import {createAction} from '@reduxjs/toolkit'
import {SelectedTickets} from '../types'

interface SelectUnselectTicket {
  ticketId: string
  count: number
  placeId?: string
}

export const selectTicket = createAction<SelectUnselectTicket>('SELECT_TICKET')

export const selectTickets = createAction<SelectedTickets>('SELECT_TICKETS')

export const unselectTicket = createAction<SelectUnselectTicket>('UNSELECT_TICKET')
