import {createReducer} from '@reduxjs/toolkit'
import {closeNativeModal, openNativeModal} from '../actions/modals'
import {ModalsState} from '../types'

const defaultState: ModalsState = {
  modalType: '',
}

export const modals = createReducer(defaultState, builder => {
  builder
    .addCase(openNativeModal, (state, action) => ({modalType: action.payload}))
    .addCase(closeNativeModal, () => defaultState)
})
