import {Category, DescribeSeatingPlanResponse} from '@wix/ambassador-seating-v1-seating-plan/types'
import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {getEventId} from '@wix/wix-events-commons-statics'
import {SelectedPlace} from '../types/seating'
import {getTicketDefinitionByExternalId} from '../selectors/seating'
import {ThunkConfig} from './interfaces'

export const describeSeatingPlan = createAsyncThunk<DescribeSeatingPlanResponse, void, ThunkConfig>(
  'DESCRIBE_SEATING_PLAN',
  async (_, {getState, extra: {serverApi}}) => {
    const plan = await serverApi.describeSeatingPlan(getEventId(getState().event))
    const {tickets} = getState()
    plan.plan.categories = plan.plan.categories.filter(({externalId}) =>
      getTicketDefinitionByExternalId(tickets, externalId),
    )

    return plan
  },
)

export const selectPrice = createAsyncThunk<Category[], string, ThunkConfig>(
  'SELECT_PRICE',
  (selectedPrice, {getState}) => {
    const state = getState()
    const categories = state.seating.plan.categories
    return selectedPrice
      ? categories.filter(category => {
          const ticketDefinition = getTicketDefinitionByExternalId(state.tickets, category.externalId)
          return selectedPrice === ticketDefinition.price.value
        })
      : categories
  },
)

export const selectLocation = createAction<string>('SELECT_LOCATION')

export const selectPlace = createAction<SelectedPlace>('SELECT_PLACE')

export const selectPlaces = createAction<SelectedPlace[]>('SELECT_PLACES')

export const unselectPlace = createAction<string>('UNSELECT_PLACE')

export const setShowAccessibilityMode = createAction<boolean>('SET_SHOW_ACCESSIBILITY_MODE')
