import {createReducer} from '@reduxjs/toolkit'
import {
  describeSeatingPlan,
  selectPlace,
  unselectPlace,
  selectPlaces,
  selectPrice,
  selectLocation,
  setShowAccessibilityMode,
} from '../actions/seating'
import {SeatingState} from '../types'
import {checkout} from '../actions/checkout'
import {closeNativeModal} from '../actions/modals'

const defaultState: SeatingState = {
  plan: null,
  loading: false,
  selectedPlaces: [],
  selectedPrice: null,
  selectedZone: null,
  filteredCategories: [],
  showAccessibilityMode: false,
  planReservations: {},
}

export const seating = createReducer<SeatingState>(defaultState, builder => {
  builder
    .addCase(describeSeatingPlan.fulfilled, (state, action) => {
      const plan = action.payload.plan
      const planReservations = action.payload.planReservations

      return {
        ...state,
        plan,
        planReservations,
        filteredCategories: plan.categories.filter(category => category.totalCapacity > 0),
      }
    })
    .addCase(selectPrice.fulfilled, (state, action) => ({
      ...state,
      selectedPrice: action.meta.arg,
      filteredCategories: action.payload,
    }))
    .addCase(selectLocation, (state, action) => ({...state, selectedZone: action.payload}))
    .addCase(selectPlace, (state, action) => ({...state, selectedPlaces: [...state.selectedPlaces, action.payload]}))
    .addCase(selectPlaces, (state, action) => ({
      ...state,
      selectedPlaces: [...state.selectedPlaces, ...action.payload],
      showAccessibilityMode: false,
    }))
    .addCase(unselectPlace, (state, action) => {
      const selectedPlaces = [...state.selectedPlaces]
      const index = selectedPlaces.findIndex(seat => seat.id === action.payload)

      selectedPlaces.splice(index, 1)

      return {
        ...state,
        selectedPlaces,
      }
    })
    .addCase(checkout.pending, state => ({...state, loading: true}))
    .addCase(setShowAccessibilityMode, (state, action) => ({...state, showAccessibilityMode: action.payload}))
    .addCase(closeNativeModal, state => ({...state, loading: false}))
})
