import {createReducer} from '@reduxjs/toolkit'
import {CHANGE_EVENT} from '../actions/event'
import {selectTicket, unselectTicket, selectTickets} from '../actions/selected-tickets'
import {SelectedTickets} from '../types'

const defaultState: SelectedTickets = {}

export const selectedTickets = createReducer(defaultState, builder => {
  builder
    .addCase(selectTicket, (state, {payload: {ticketId, count, placeId}}) => {
      const currentPlaceIds = state[ticketId]?.placeIds ?? []

      return {
        ...state,
        [ticketId]: {
          quantity: count,
          placeIds: placeId ? [...currentPlaceIds, placeId] : currentPlaceIds,
        },
      }
    })
    .addCase(selectTickets, (state, {payload}) => ({...state, ...payload}))
    .addCase(unselectTicket, (state, {payload: {ticketId, count, placeId}}) => ({
      ...state,
      [ticketId]: {
        quantity: count,
        placeIds: state[ticketId].placeIds.filter(place => place !== placeId),
      },
    }))
    .addCase(CHANGE_EVENT, () => defaultState)
})
